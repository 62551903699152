.exhibition{
	h1{
		color: white;
		margin-top: 25rem;
		background-color: rgba(0,0,0,.25);
		padding:10px 5px;
	}

	h4{
		color: white;
		padding: 10px 5px;
		background-color: rgba(0,0,0,.25);
	}

	.splash{
		min-height: 30rem;
		background-size: cover;
		background-position: 50% 50%;
	}
}