.exhibitions{
	a{
		color: black;
		text-decoration: none;
		&:hover{
			color: #404041;
			cursor: pointer;
		}
		&.active{
			text-decoration: underline;
		}
		&.nav-btn{
			background-color: black;
			color: white;
			&:hover{
				background-color: #404041;
			}
		}
	}

	h5{
		color: #aaa;
	}

	.footerPush{
		a{
			color: blue;
		}

		margin-top: 10rem;
		margin-bottom: 25rem;
	}
	
}