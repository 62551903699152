@import "ember-bootstrap/bootstrap";
@import url('https://use.typekit.net/xnj0gjx.css');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,400;0,700;1,200;1,400;1,700&display=swap');
@import 'index.scss';
@import 'nav-bar';
@import 'nav-footer';
@import 'forms';
@import 'exhibitions';
@import 'exhibition';

h1,h2{
	font-family: novecento-sans-wide, sans-serif;
}

body{
	font-family: 'Raleway', sans-serif;
	//font-family: novecento-sans-wide, sans-serif;
}

.ember-google-map{
	width:100%;
	height: 500px;
}

.btn-primary{
	background-color: black;
	color: white !important;
}