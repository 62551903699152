.navbar{
	font-family: novecento-sans-wide, sans-serif;
	border: 1px solid #efefef;
	padding:2px;
	a{
		color: black;
		text-decoration: none;
		&:hover{
			color: #404041;
			cursor: pointer;
		}
		&.active{
			text-decoration: underline;
		}
		&.nav-btn{
			background-color: black;
			color: white;
			&:hover{
				background-color: #404041;
			}
		}
	}
}